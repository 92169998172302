import gsap from 'gsap';
import { type CSSProperties, useEffect, useRef, useState } from 'react';
import useIsomorphicLayoutEffect from '#app/hooks/useIsomorphicLayoutEffect.ts';
import { type Gradient } from '#app/lib/firebase/models/gradient.ts';

export type BackgroundGradientProps = {
  // transition duration in seconds
  duration: number;
  gradients: Array<Gradient>;
};

export const BackgroundGradient = ({ duration, gradients }: BackgroundGradientProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const timeline = useRef<gsap.core.Timeline>();
  const [styles, setStyles] = useState<CSSProperties>();

  useIsomorphicLayoutEffect(() => {
    function repeat() {
      // @ts-ignore
      timeline.current.play(duration);
    }

    // timeline.current = gsap.timeline({ paused: true, repeat: -1, yoyo: true });
    timeline.current = gsap.timeline({ onComplete: repeat });
    // @ts-ignore
    const items: Array<HTMLDivElement> = Array.from(containerRef.current.querySelectorAll<HTMLDivElement>('div'));

    let ctx = gsap.context(() => {
      items.forEach((element, index) => {
        // insert first animation at a time of 0
        // all other animations at a time that will overlap with the previous animation fading out.
        const position = index === 0 ? 0 : '-=' + duration;

        // @ts-ignore
        timeline.current.to(
          element,
          {
            duration: duration,
            opacity: 1,
            repeat: 1,
            // zIndex: 1,
            yoyo: true,
            repeatDelay: duration
          },
          position
        );
        //when the last image fades out we need to cross-fade the first image
        if (index === items.length - 1) {
          // @ts-ignore
          timeline.current.to(items[0], { duration: duration, opacity: 1 }, position);
        }
      });
    });

    return () => ctx.revert(); // cleanup
  }, []);

  useEffect(() => {
    if (gradients) {
      const styles: CSSProperties = {};
      gradients.forEach((gradient, index) => {
        // @ts-ignore
        styles[`--gradient-${index}-color1`] = gradient.color1;
        // @ts-ignore
        styles[`--gradient-${index}-color2`] = gradient.color2;
        // @ts-ignore
        styles[`--gradient-${index}-color3`] = gradient.color3;
      });

      setStyles(styles);
    }
  }, [gradients]);

  return (
    <div ref={containerRef} className="wow-gallery-background" style={styles}>
      {gradients && gradients.map((gradient, index) => <div key={index} />)}
    </div>
  );
};
